
#container{
    height: 100vh;
    width: 100%;
    margin: 0 auto;
text-align: center;
}
h1{
    color:#004275;
    font-family:sans-serif;
    font-size: 2rem;
    text-align: center;
}
.service-content{
    font-size: 2rem;
    text-align: center;
    color:#004275;
}

.content-container{
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.content-container:nth-last-child(1){
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: row;
   position: relative;
   bottom: 8rem;
    justify-content: center;
    align-items: center;
}
.content-container:nth-last-child(2){
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: row;
   position: relative;
   bottom: 4rem;
    justify-content: center;
    align-items: center;
}
.content-text{
    width: 500px;
    height: 600px;
    font-size: 1.4rem;
    color: #000000;
    background-color: #ffffff;
    box-shadow: 3px 2px 2px 3px rgb(255, 255, 255);
    text-align: left;
    padding: 2rem;
    margin: 0px 19px;
    line-height: 2rem;
    overflow: hidden;
}
.service-image{
    width: 600px;
    height: 300px;
    border-radius: 0px;
}
@media screen and (max-width:1200px){
    .content-container{
        width: 100%;
        height:fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 3rem 0px;
        align-items: center;
    }
    .content-container:nth-last-child(1){
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
       position: relative;
        justify-content: center;
        align-items: center;
    }
    .content-container:nth-last-child(2){
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
       position: relative;
        justify-content: center;
        align-items: center;
    }
    .content-text{
        width: 600px;
        height: fit-content;
        font-size: 1.4rem;
        color: #000000;
        background-color: #ffffff;
        box-shadow: 3px 2px 2px 3px rgb(255, 255, 255);
        text-align: left;
        margin: 2rem 2rem;
        line-height: 2rem;
        overflow: hidden;
    }
}
@media screen and (max-width:550px){
    .content-text{
        width: 350px;
        height: fit-content;
        font-size: 1.4rem;
        color: #000000;
        background-color: #ffffff;
        box-shadow: 3px 2px 2px 3px rgb(255, 255, 255);
        text-align: left;
        margin: 2rem 2rem;
        line-height: 2rem;
        overflow: hidden;
    }
    h1{
        color:#004275;
        font-family:sans-serif;
        font-size: 1.3rem;
        text-align: center;
    }
    .service-content{
        font-size: 1.2rem;
        text-align: center;
        color:#004275;
    }
}