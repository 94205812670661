*{
    font-family: 'Times New Roman', Times, serif;
}
.appointment-container{
    background-image: linear-gradient(120deg, rgba(3, 0, 92, 0.5)50%, rgba(0, 18, 110, 0.5)50%), url(/public/Images/nurse.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.appointment{
    margin: 0 auto;
    width: 50%;
    padding: 2rem;
}

.heading-appointment{
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.content-appointment{
    font-size: 1.5rem;
}
.pop-up {
    background: #000000;
    width: 600px;
    margin: 0 auto;
    color: #fff;
    padding: 12px;
    margin-top: 12vh;
}
.email{
    color: #fff;
}

#btn-2 {
    background: transparent;
    color: white;
    font-size: 20px;
    padding: 10px;
    margin: 8px;
    transition-delay: 2ms;
    justify-content: center;
    cursor: pointer;
}
.email{
    color: rgb(255, 255, 255);
}

#btn-2:hover {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
}
form{
    width: 100%;
    padding: 1.5rem;
    margin: 0 auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.content{
    color: #fff;
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
    margin: 1.3rem 0;
}

input{
    padding: 0.5rem;
    font-size: 1.3rem;
    width: 400px;
    border: none;
}

textarea{
    padding: 0.5rem;
    font-size: 1.3rem;
    width: 400px;
    border: none;
}
.button{
    background-color: rgb(0, 73, 73);
    color: #fff;
    cursor: pointer;
    padding: 1.1rem;
    width: fit-content;
}

@media screen and (max-width:1000px){
    .appointment{
        margin: 0 auto;
        width: 100%;
        padding: 2rem;
    }
}

@media screen and (max-width:500px){
    .appointment{
        margin: 0 auto;
        width: 100%;
        padding: 2rem;
    }
    .content-appointment{
        font-size: 1rem;
    }
    
    .content{
        color: #fff;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        margin: 1.3rem 0;
    }
    input{
        padding: 0.5rem;
        font-size: 1rem;
        width: 250px;
        border: none;
    }
    textarea{
        width: 250px;
        font-size: 1rem;
    }
    .heading-appointment{
        color: #fff;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 1.4rem;
    }
    .content-appointment{
        font-size: 1.2rem;
    }
}