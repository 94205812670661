.navbar{
    background-color: #002c69;
    height: 120px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    z-index: 1;
    top: 0;
}
#logo{
    color: #f7f7f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
#social-icons{
    font-size: 1.7rem;
    width: fit-content;
    text-align: center;
    height: fit-content;
    
}
#social-icons :hover{
    font-size: 2rem;
    width: fit-content;
    text-align: center;
    justify-content: end;
    height: fit-content;
   
}
.social {
    margin: 0 1.1rem;
    transition-duration: 1s;
 
}
.social :hover{
    color: #6ea1e4;
}

.menu-bars{
    margin-left: 2em;
    font-size: 2rem;
    background:none;
}
.nav-menu{
    background-color: #002c69;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center; 
    position: fixed;
    top: 0;
    left : -100%;
    transition:2s
}

.nav-menu.active{
    left: 0;
    transition-duration: 1.5s;
}

.nav-text{
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
}

.nav-text a{
    text-decoration: none;
    color: #ffffff;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    transition: 2.5s ease-in-out;
    border-radius: 4px;
}
.nav-text a:hover{
    background-color:  #6ea1e4;
    color: #ffffff;
   
}

.nav-menu-items{
    width: 100%;
}

.navbar-toggle{
    background-color: #002c69;
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: start;
    align-items: center;

    
}

span{
    margin-left: 16px;
}

@media screen and (max-width:700px){

    .navbar{
        background-color: #002c69;
        height: 150px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        z-index: 1;
        top: 0;
    }
    #social-icons{
        font-size: 1rem;
        width: fit-content;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: end;
        height: fit-content;
    }
    .social {
        margin: 0.4rem 1.1rem;
        transition-duration: 1s;
     
    }
    .social :hover{
        color: #6ea1e4;
    }

}
