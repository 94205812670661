
@keyframes change-image{
    0%{
        background-image: url(/public/Images/africa-humanitarian-aid-doctor-taking-care-patient.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    40%{
        background-image: url(/public/Images/injection.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    70%{
        background-image: url(/public/Images/scan.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    100%{
        background-image: url(/public/Images/african-american-female-doctor-operating-room-putting-drugs-through-iv-surgery-concepts.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
h1{
    color: rgb(255, 255, 255);
}
}
header{
    animation: change-image 30s ease-in-out infinite alternate;
    height: 1000px;
    width: 100%;
}
.header-container{
    position: relative;
    top: 25%;
    width: 50%;
    padding: 2rem;
    
}

.head{
color:#fff;
font-family: 'Times New Roman', Times, serif;
font-style: oblique;
font-size: 2.3rem;
}
p{
    color:#fff;
    font-family: 'Times New Roman', Times, serif;
    font-style: oblique;
font-size: 2rem;
}
li{
    font-family: 'Times New Roman', Times, serif;
    font-style: oblique;
}
button{
    background-color: black;
    padding: 1em;
    border: none;
    transition: 2s;
    margin: 0 auto;
}
button a{
    color:rgb(255, 255, 255);
    font-family: 'Times New Roman', Times, serif;
    font-style: oblique;
    font-size: 1.3rem;
    transition: 2s;
}
button a:hover{
    color: black;
}
button:hover{
    background-color: white;
}
.word{
    background-color: #000000;
    width: 100%;
    font-family: 'Times New Roman', Times, serif;
    font-style: oblique;
    font-size: 1.3rem;
    height: fit-content;
    padding: 1rem;
}
main{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    background-color: rgb(255, 255, 255);
}
.main-text{
    font-size: 1.2rem;
    line-height: 2.5em;
}
@keyframes change-box-1{
    0%{
        background-color: black;
        background-repeat: no-repeat;
        background-size: cover;
    }
    30%{
        background-color: #2a1362;
        background-repeat: no-repeat;
        background-size: cover;
    }
    60%{
        background-color: #0b3641;
        background-repeat: no-repeat;
        background-size: cover;
    }
    100%{
        background-color: #801b40;
        background-repeat: no-repeat;
        background-size: cover;
    }


}
@keyframes change-box-2{
    0%{
        background-color: black;
        background-repeat: no-repeat;
        background-size: cover;
    }
    30%{
        background-color:  #2a1362;
        background-repeat: no-repeat;
        background-size: cover;
    }
    60%{
        background-color: #0b3641;
        background-repeat: no-repeat;
        background-size: cover;
    }
    100%{
        background-color:  #801b40;
        background-repeat: no-repeat;
        background-size: cover;
    }


}
.main-container{
    animation: change-box-1 20s ease-in-out infinite alternate;
    width: 50%;
    height: 600px;
    padding: 1.9em;
}
.main-container:nth-of-type(2){
    animation: change-box-2 20s ease-in-out infinite alternate;
    width: 50%;
    height: 600px;
}
.main-heading{
    color:#fff;
font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
font-size: 2rem;
}
.box2{
    font-size: 1.4rem;
}
table{
    color: rgb(255, 255, 255);
    width: 100%;
    font-size: 1.4rem;
    background-image: linear-gradient(90deg, rgb(0,0,0,0.5)50%, rgb(0,0,0,0.5)50%), url(/public/Images/741804.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 9px;
}
tr, td{
    padding: 20px;
    font-family: 'Times New Roman', Times, serif;
    font-style: oblique;
}
.main-container-2{
background-color: #002c69;
    width: 50%;
    color: #fff;
    height: 1000px;
    padding: 1.9em;
}
.main-container-2:nth-of-type(2){
background-color: #002c69;
    width: 50%;
    overflow: hidden;
    padding: 2em;
    height: 1000px;
}

.image-container{
    overflow: hidden;
    height: 350px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
img{
    border-radius: 15px;
}

@media screen and (max-width: 1180px){
    .header-container{
        position: relative;
        top: 0%;
        left: -10%;
        width: 80%;
        padding: 2rem;
    }
        header{
        animation: change-image 20s ease-in-out infinite alternate;
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;;
        width: 100%;
    }
    .word{
        background-color:  #000000;
        width: 80%;
        height: fit-content;
        padding: 1rem;
    }
    main{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        background-color: rgb(255, 255, 255);
    }
    .main-container{
        animation: change-box-1 20s ease-in-out infinite alternate;
        width: 100%;
        height: 490px;
        padding: 1.9em;
    }
    .main-container:nth-of-type(2){
        animation: change-box-2 20s ease-in-out infinite alternate;
        width: 100%;
        height: 490px;
    }
    .main-container-2{
        background-color: #002c69;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 900px;
            padding: 1.9em;
        }
        .main-container-2:nth-of-type(2){
        background-color: #002c69;
            width: 100%;
            justify-content: center;
            align-items: center;
            padding: 3rem;
            display: flex;
            flex-direction: column;
            height: 900px;
        }
}
@media screen and (max-width: 750px){
    header{
        animation: change-image 20s ease-in-out infinite alternate;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;;
        width: 100%;
    }
       
    .header-container{
        position: relative;
        top: 0%;
        left: 0%;
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 2rem;
    }
    .main-container-2{
        background-color: #002c69;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 900px;
            padding: 1.9em;
        }
        .main-container-2:nth-of-type(2){
        background-color: #002c69;
            width: 100%;
            justify-content: center;
            align-items: center;
            padding: 3rem;
            display: flex;
            flex-direction: column;
            height: 900px;
        }
}

@media screen and (max-width: 660px) {
    .head{
        color:#fff;
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        font-size: 1.7rem;
        }
        p{
            color:#fff;
        font-family:'Times New Roman', Times, serif;
        font-size: 1.3rem;
        }
        button a{
            color:rgb(255, 255, 255);
            font-size: 1.2rem;
            transition: 2s;
        }
        button{
            background-color: black;
            padding: 0.4em;
            border: none;
            transition: 2s;
        }
}
@media screen and (max-width:550px){
    header{
        animation: change-image 20s ease-in-out infinite alternate;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;;
        width: 100%;
    }
    .main-heading{
        color:#fff;
    font-family:'Times New Roman', Times, serif;
    font-size: 1.5rem;
    text-align: center;
    }
    table{
    color: rgb(255, 255, 255);
    width: 100%;
    font-size: 1rem;
    background-image: linear-gradient(90deg, rgb(0,0,0,0.5)50%, rgb(0,0,0,0.5)50%), url(/public/Images/741804.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 9px;
}
.main-text{
    font-size: 1rem;
    line-height: 2.5em;
}
    .main-container-2{
        background-color: #002c69;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 900px;
            padding: 1.9em;
        }
    .head{
        color:#fff;
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        font-size: 1rem;
        }
        p{
            color:#fff;
        font-family:'Times New Roman', Times, serif;
        font-size: 1rem;
        }
        button a{
            color:rgb(255, 255, 255);
            font-size: 0.8rem;
            transition: 2s;
        }
        button{
            background-color: black;
            padding: 0.4em;
            border: none;
            transition: 2s;
        }
    }
    @media screen and (max-width:460px){
        .main-container{
            animation: change-box-1 20s ease-in-out infinite alternate;
            width: 100%;
            height: 590px;
            padding: 1.9em;
        }
        
    }
    @media screen and (max-width:360px){
        .main-container{
            animation: change-box-1 20s ease-in-out infinite alternate;
            width: 100%;
            height: 800px;
            padding: 1.9em;
        }
        .main-container:nth-of-type(2){
            animation: change-box-2 20s ease-in-out infinite alternate;
            width: 100%;
            height: 490px;
        }
        .main-container-2{
            background-color: #002c69;
                width: 100%;
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                height: 1200px;
                padding: 1.9em;
            }
            .main-container-2:nth-of-type(2){
            background-color: #002c69;
                width: 100%;
                justify-content: center;
                align-items: center;
                padding: 3rem;
                display: flex;
                flex-direction: column;
                height: 960px;
            }
    }