*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: sans-serif
}



.home, .about, .contact{
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}

